.landingWrapper {
  /* padding: 0.5% 4%;  */
  /* max-width: 1520px; */
  overflow: auto !important;
}
.coin iframe {
  margin-top: 5%;
  width: 40vw;
  height: 20vw;
  border: none;
}
.subscribe iframe {
  width: 25vw;
  height: 25vw;
  padding-left: 10%;
  border: none;
}
.navbar {
  display: flex;
  padding: 0.5% 4%;
  justify-content: space-between;
  align-items: center;
}
.navbarRight {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbarRight button {
  margin: 7px;
  font-size: 90%;
}

.navbarLogin {
  border: none;
  background-color: rgba(240, 248, 255, 0);
}

.navbarRegester {
  border: 2px solid black;
  background-color: rgba(240, 248, 255, 0);
  padding: 7px 25px;
  border-radius: 7px;
}

.navbarLeft img {
  margin: 15%;
}

.navbarLeft h1 {
  font-weight: 700;
}

.mainPart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2%;
}
.mainText img {
  margin-bottom: -21%;
  width: 75%;
}
.mainText h1 {
  color: var(--Text);
  font-size: 350%;
}

.mainText {
  text-align: right;
  direction: rtl;
}

.mainLogin {
  color: var(--Accent);
  border: none;
  margin-left: 7%;
  background-color: rgba(240, 248, 255, 0);
}

.mainRegester {
  border: none;
  color: var(--light-ButtonText);
  background-color: var(--Accent);
  padding: 10px 35px;
  border-radius: 7px;
}

.mainButton Button {
  font-size: 1em;
  font-weight: 600;
}

.coin img {
  margin-top: 5%;
  width: 80%;
}

.freePart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1%;
  background-color: #f5f1f9;
}

.freeText h1 {
  font-size: 300%;
  color: var(--Text);
}

.freeText {
  text-align: right;
  margin: 2% 0;
  direction: rtl;
  margin-left: 15%;
  margin-right: -0%;
}

.ferrButton {
  border: none;
  color: var(--light-ButtonText);
  background-color: var(--Accent);
  padding: 10px 35px;
  border-radius: 7px;
}

.freeText button {
  font-size: 1em;
  font-weight: 600;
}

.subscribe img {
  width: 90%;
  padding-left: 10%;
}

.reportPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #eae5f8;
}
.reportBox {
  display: flex;
  justify-content: space-around;
}
.box {
  background-color: var(--button-Text);
  /* margin: 0 0%; */
  border-radius: 30px;
  font-size: 13px;
  padding: 2% 4.5%;
  text-align: center;
}
.reportPartText {
  width: 72%;
}
.img {
  width: 30%;
}
.img img {
  padding-left: 20%;
  width: 75%;
}
.reportPartTextH {
  font-size: 3em;
  margin-bottom: 50px;
  text-align: center;
  color: var(--Text);
  margin-left: -4%;
}

.introducePart {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Blob {
  margin-top: -50px;
  margin-left: 350px;
  width: 40%;
}
.introduce {
  margin: 20px 0;
}
.introduceimg {
  margin-left: -650px;
  width: 60%;
  border-radius: 10px;
}
.introduceText {
  direction: rtl;
  text-align: right;
}
.introduceText h1 {
  color: var(--Text);
  font-size: 3em;
  margin-bottom: -20px;
}
.introduceText h2 {
  color: var(--Text);
  margin-bottom: 30px;
}
.introducebutton {
  border: none;
  color: var(--light-ButtonText);
  background-color: var(--Accent);
  padding: 10px 35px;
  border-radius: 7px;
  font-size: 1em;
  font-weight: 600;
}

.earnPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5%;
  color: var(--Text);
  text-align: center;
  background-color: #eae5f8;
}
.earnMain {
  width: 100%;
}
.layer {
  width: 50%;
  /* margin-right: -15%; */
}
.layer img {
  width: 100%;
  /* padding-right: -400px; */
}
.earnbutton {
  border: none;
  color: var(--light-ButtonText);
  background-color: var(--Accent);
  padding: 10px 35px;
  border-radius: 7px;
  font-size: 1em;
  font-weight: 600;
}
.earnMain h1 {
  font-size: 3em;
}
.earnMain h2 {
  padding-bottom: 10px;
  font-size: 2em;
}

.commentsIcom {
  display: flex;
  justify-content: space-between;
  padding: 3% 3% 0 3%;
}
.Arrow {
  margin-right: 15px;
}
.minbox {
  width: 100%;
  height: 50vh;
  /* border-radius: 50px; */
  background-size: cover;
  background-image: url(../../assets//img//Video.png);
}

.comments {
  padding-bottom: 2%;
}

.profit {
  padding-bottom: 5%;
}

.download {
  background-color: #f5f1f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
}
.mobile {
  width: 50%;
}
.mobile img {
  width: 40%;
  margin-right: -32%;
}
.line2 {
  width: 40%;
  margin-left: 20%;
}
.downloadtext {
  width: 30%;
}
.downloadtext h1 {
  color: var(--Text);
  font-size: 3.5em;
}
.google img {
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
  margin-left: 5px;
}

.app img {
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
}

@media screen and (max-width: 1130px) {
  .mainText h1 {
    font-size: 280%;
  }
  .mainText {
    margin-right: 5%;
  }
  .freeText {
    margin-right: 5%;
  }
  .freeText h1 {
    font-size: 200%;
  }
  .box h1 {
    font-size: 180%;
  }
  .introduceText {
    margin-right: 5%;
    font-size: 90%;
  }
  .introduceimg {
    margin-left: -570px;
  }
  .layer {
    margin-left: 5%;
  }
  .earnMain {
    font-size: 80%;
  }
  .minbox {
    height: 40vh;
  }
  .downloadtext {
    font-size: 90%;
    margin-left: -10%;
  }
  .downloadBox {
    width: 100%;
  }
}

@media screen and (max-width: 1070px) {
  .mainText h1 {
    font-size: 250%;
  }
  .freeText h1 {
    margin-left: -15%;
  }
  .subscribe img {
    width: 70%;
  }
  .box h1 {
    font-size: 150%;
  }
  .introduceText {
    font-size: 70%;
    width: 70%;
  }
  .introduceimg {
    margin-left: -370px;
    width: 60%;
  }
  .layer {
    margin-left: 5%;
  }
  .Blob {
    width: 100%;
    height: 100%;
  }
  .earnMain {
    font-size: 80%;
  }
  .minbox {
    height: 34vh;
  }
  .downloadtext {
    font-size: 80%;
    margin-left: -10%;
  }
  .downloadBox {
    width: 110%;
  }
  .mobile {
    margin-left: -20%;
  }
}

@media screen and (max-width: 950px) {
  .mainText h1 {
    font-size: 200%;
  }
  .mainText img {
    width: 64%;
    margin-bottom: -19%;
  }
  .freeText h1 {
    margin-left: -15%;
    font-size: 130%;
  }
  .freeText {
    width: 30%;
  }
  .subscribe img {
    width: 70%;
  }
  .box h1 {
    font-size: 130%;
  }
  .introduceText {
    font-size: 60%;
    padding: 5% 0;
    width: 80%;
  }
  .introduceimg {
    margin-left: -300px;
    width: 60%;
  }
  .layer {
    margin-left: 8%;
  }
  .Blob {
    width: 30%;
    margin-top: -50px;
    margin-left: 250px;
  }
  .earnMain {
    font-size: 70%;
    width: 120%;
  }
  .minbox {
    height: 28vh;
  }
  .downloadtext {
    font-size: 70%;
    width: 50%;
  }
  .downloadBox {
    width: 70%;
  }
  .mobile {
    margin-left: 8%;
  }
}

@media screen and (max-width: 760px) {
  .mainText h1 {
    font-size: 170%;
  }
  .mainText button {
    font-size: 70%;
  }
  .coin {
    width: 70%;
    margin: 0 5%;
  }
  .freeText h1 {
    margin-left: -45%;
    font-size: 110%;
  }
  .freeText button {
    font-size: 70%;
  }
  .box h1 {
    font-size: 80%;
  }
  .box {
    width: 90%;
  }
  .reportBox {
    width: 95%;
  }
  .reportPartTextH {
    font-size: 150%;
    margin: 5%;
  }
  .introduce {
    width: 90%;
  }
  .introduceText {
    font-size: 50%;
    padding: 5% 0;
    width: 90%;
  }
  .introduceimg {
    margin-left: -290px;
    width: 80%;
  }
  .Blob {
    width: 40%;
    margin-left: 190px;
  }
  .earnMain {
    font-size: 50%;
    width: 120%;
  }
  .minbox {
    height: 20vh;
  }
  .downloadtext {
    font-size: 55%;
    width: 50%;
  }
  .downloadBox {
    width: 70%;
  }
  .mobile {
    margin-left: 8%;
  }
  .app img {
    border-radius: 5px;
  }
  .google img {
    border-radius: 5px;
  }
}

@media screen and (max-width: 560px) {
  .mainText h1 {
    font-size: 150%;
  }
  .mainText button {
    font-size: 50%;
  }
  .freeText h1 {
    margin-left: -45%;
    font-size: 90%;
  }
  .freeText {
    width: 60%;
  }
  .freeText button {
    font-size: 50%;
    padding: 7% 20%;
  }
  .box h1 {
    font-size: 60%;
  }
  .box {
    width: 90%;
  }
  .reportBox {
    width: 90%;
  }
  .reportPartTextH {
    font-size: 100%;
  }
  .introduce {
    width: 70%;
  }
  .introduceText {
    font-size: 40%;
    width: 50%;
  }
  .introduceimg {
    margin-left: -250px;
    width: 80%;
  }
  .Blob {
    width: 40%;
    margin-left: 170px;
  }
  .earnMain {
    font-size: 40%;
  }
  .minbox {
    height: 17vh;
  }
  .text {
    font-size: 50%;
  }
  .downloadtext {
    font-size: 45%;
    width: 50%;
  }
  .mobile {
    margin-left: 8%;
  }
}
@media screen and (max-width: 460px) {
  .coin iframe {
    width: 40vw;
    height: 30vw;
  }
  .subscribe iframe {
    width: 40vw;
    height: 30vw;
  }
  .minbox {
    height: 14vh;
  }

  .navbarLeft img {
    width: 60%;
  }
  .navbarRight {
    font-size: 60%;
  }
  .mainPart {
    flex-direction: column;
  }
  .coin {
    margin-left: 18%;
  }
  .mainText {
    margin: 0;
    margin-bottom: 5%;
    text-align: center;
  }
  .mainText img {
    width: 35%;
    margin-bottom: -12%;
    margin-left: 11%;
  }
  .mainText h1 {
    font-size: 150%;
  }
  .freePart {
    flex-direction: column;
  }
  .freeText {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .freeText h1 {
    margin-left: 1%;
    font-size: 100%;
  }
  .img img {
    display: none;
  }
  .subscribe {
    margin-top: 5%;
    width: 80%;
  }
  .freeText button {
    font-size: 60%;
    padding: 2% 10%;
    margin-bottom: 5%;
  }
  .box {
    margin-bottom: 5%;
    width: 90%;
  }
  .reportBox {
    margin: auto;
    width: 90%;
  }
  .introducePart {
    flex-direction: column;
  }
  .reportPartText {
    width: 100%;
  }
  .reportPartTextH {
    font-size: 100%;
  }
  .introduce {
    width: 70%;
    margin-bottom: -10%;
  }
  .introduceText {
    font-size: 40%;
    margin: 0;
    text-align: center;
    width: 100%;
  }
  .introduceimg {
    /* margin-left: -220px; */
    width: 100%;
    margin: auto;
  }
  .Blob {
    display: none;
    /* width: 40%;
            margin-left: 150px; */
  }
  .earnMain {
    font-size: 25%;
  }
  .earnbutton {
    font-size: 40%;
  }
  .text {
    font-size: 30%;
  }
  .downloadtext {
    font-size: 30%;
    width: 50%;
  }
  .mobile {
    margin-left: 8%;
  }
  .minbox {
    height: 12vh;
  }
}

@media screen and (max-width: 400px) {
}
