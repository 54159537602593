.dashboard h1 {
  color: var(--Accent);
  font-weight: 700;
  display: flex;
  justify-content: center;
  background: var(--TextGradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10px;
  text-align: center;
}

.collapsController {
    width: 22px;
    height: 22px;
    padding: 6px;
    background: #fbfbfb;
    border-radius: 8px;
    border: 1px #e2e2e2 solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    position: absolute;
    margin-left: -22px;
    margin-top: 5%;
    cursor: pointer;
    rotate: 180deg;
}
.Rotate{
    rotate: 0deg;
}
.menu {
  direction: rtl;
  color: var(--text);
  height: 240px;
  background-color: var(--Primary) !important;
}
.menu aside{
    border-color: transparent !important;
}
.menu div,a{
  background-color: var(--Primary) !important;
font-weight: 500;
}
.menu span{
    color: var(--Text) !important;
}
.menu div:hover{
    background-color: var(--Primary) !important;
  }
.menu h6 {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 12px;
  color: var(--Subtext);
}
MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}
.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
  gap: 10px;
}
.dashboard{
  height: 100vh;
}
.menuWraper{
    background-color: var(--Primary);

}
.dashboard img {
  margin-top: -10%;
}
.icon {
  display: inline;
  cursor: pointer;
}
.icon1 {
  display: none;
  cursor: pointer;
}
.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomImg {
  display: flex;
  align-items: center;
  direction: var(--direction-ref);
  color: var(--Text);
  gap: 5px;
}
.bottomImg h6 {
  color: var(--Text);
}
.bottomIcon {
  background-color: var(--Interactive);
  color: var(--Text);
  text-align: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 20px;
}
.bottomProf {
  margin: 0 5%;
}
.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}
.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
  padding-bottom: 10px;
  cursor: pointer;
  
}
.logoutimg img {
  width: 50%;
  margin: 0;
}
.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}
.logouticon {
  cursor: pointer;
}
.BottomMenu {
  position: absolute;
  bottom: 0px;
}
.submenumargin{
    margin-right: 15px;
}
h6{
  margin: 10px 0px 10px 0px;
}
/* #SidebarControl{
    direction: var(--direction-ref);
} */