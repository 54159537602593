.dashboard h1 {
  color: var(--Accent);
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
}

.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}
a {
  border-radius: 17px;
}
MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.signalWraper {
  display: flex;
  direction: var(--direction);
}

.main {
  width: calc(100vw - 250px);
  direction: rtl;
  height: 100vh;
  background-color: var(--Primary);
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
}
.mainbox {
  direction: ltr;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.box {
  display: flex;
  margin: 25px 0;
  justify-content: space-around;
}

.text {
  box-shadow: 1px 2px 30px 2px rgba(100, 100, 100, 0.134);
  padding: 10px 14px;
  border-radius: 17px;
  direction: rtl;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}
.iconbox span {
  font-weight: 500;
}

.iconbox button {
  background-color: var(--Success);
  border: none;
  width: 50px;
  border-radius: 20px;
  color: var(--ButtonText);
}
.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}
.iconbox {
  display: flex;
  align-items: center;
}
.black button {
  font-size: 12px;
  padding: 5px;
}
.green button {
  background-color: var(--Success);
  font-size: 12px;
}
.red button {
  background-color: var(--Error);
  font-size: 12px;
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.cryptoIcon {
  display: block;
  height: 30px;
  border-radius: 90px;
}