.pagebody{
    height: 100vh;
    width: 100vw;
    background: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.pagebody h1{
    color: var(--Text);
    display: flex;
    align-items: center;
    gap: 10px;
}
.pagebody h2{
    color: var(--Text);
}
.back{
    text-align: center;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 10px;
    background: var(--Accent) !important;
    color: var(--ButtonText);
}
.back span{
    margin-top: 1px;
}