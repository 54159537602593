.PlansInfoWraper {
  display: flex;
  direction: var(--direction);
  font-family: var(--font) !important;
}
.main {
  width: calc(100vw - 250px);
  direction: var(--direction-ref);
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}
.fader {
  filter: blur(10px);
}
.textpointer {
  cursor: pointer;
}
.slogan {
  direction: var(--direction-ref);
  height: 100px;
  font-size: 2em;
  width: 70%;
  margin: 50px auto;
  text-align: center;
}
.sloganInner {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  direction: var(--direction);
  justify-content: center;
}
.planDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.planFName {
  font-size: 2em;
  color: var(--Accent);
  font-weight: 900;
  background: var(--TextGradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.planCardPrice {
  text-align: center;
  font-weight: bold;
}
.TXIDDialogElements {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.planFDes {
  color: var(--Subtext);
  margin-bottom: 50px;
}
.sloganInner2 span {
  color: var(--Accent);
  font-weight: 900;
}
.planCardP {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  gap: 20px;
}

.planCard {
  background-color: var(--Secondary);
  min-width: 180px;
  width: 30%;
  box-shadow: 1px 1px 20px 0px rgba(143, 0, 255, 0.15);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(143, 0, 255, 0.15);
  -moz-box-shadow: 1px 1px 20px 0px rgba(143, 0, 255, 0.15);
  border-radius: 15px;
}
.planCardName {
  font-size: 1.5em !important;
  text-align: center;
  color: var(--Accent);
  background: var(--TextGradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 24px 0px !important;
  font-weight: bold !important;
}
.planCardItem {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px;
}
.planCardItemSpan {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.planCardButtonP {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.planCardButton {
  cursor: pointer;
  font-family: var(--font);
  width: 100px;
  height: 40px;
  border: none;
  background: var(--Accent);
  color: white;
  border-radius: 15px;
}
.PlansInfoWraper h4,
.PlansInfoWraper h2,
.PlansInfoWraper div {
  color: var(--Text);
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}
.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.PlansInfoTable {
  width: calc(100vw - 328px);
  height: calc(100vh - 72px);
  /* background-color: red; */
  padding: 20px 20px 0px 20px;
}
td {
  background: var(--Secondary);
  color: var(--Text);
}
th {
  color: var(--ButtonText);
}
.tableStyle {
  width: 100%;
}
