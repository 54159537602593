html,body{
    font-family: var(--font);
    margin: 0;
    /* overflow: hidden !important; */
}
.loginWraper {
    display: flex;
    justify-content: space-between;
    height: 730px;
    background-color: var(--Primary);
    height: 100vh;
    flex-direction: row;
}

.loginText {
    max-height: 100%;
    direction: rtl;
    flex: 1;
    display: flex;
}

.text {
    width: 80%;
    max-width: 450px;
    margin: auto;
}

.text h1 {
    font-weight: 300;
    font-size: 40px;
    margin-bottom: 50px;
}

.text h6 {
    margin: 0;
    font-weight: 500;
}
.text h5 {
    
    font-weight: 500;
}

.gradient span {
    font-weight: 900;
    background: var(--TextGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text input {
    width: 100%;
    height: 50px;
    border-radius: 15px;
    padding-right: 12%;
    /* padding: 17px 45px 17px 0 ; */
    border: 1px solid var(--Subtext);
    background-color: #ffffff00;
    position: relative;
    color: var(--Text);
}
.Sms {
    position: absolute;
    margin: 80%;
    padding-right: 50%;
    color: var(--Text);
}
.input{
    display: flex;
    align-items: center;
}
.input svg{
    position: absolute;
    margin:0px 15px;
}
.google{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    background-color: var(--Secondary);
    padding: 10px;
    width: 100%;
    border-radius: 24px;
    gap: 10px;
    margin: 10px 0px;
    direction: rtl !important;
}
a{
    text-decoration: none;
}
.google img{
    z-index: 100;
    cursor: pointer;    
}
.button {
    width: 100%;
    border-radius: 17px;
    padding: 17px 0;
    border: none;
    color: var(--ButtonText);
    font-size: 17px;
    margin-top: 0px;
    background-color: var(--Accent);
}

.tohi {
    width: 100%;
    margin: 30px 0;
    height: 0.5px;
    background-color: var(--Subtext);
}

.textEnd span {
    color: var(--Accent);
}
.moon{
    color: var(--Accent);
    margin: 0 10px;
    cursor: pointer;
}
.Sun1{
    color: var(--Accent);
    margin: 0 10px;
    cursor: pointer;
}
.sunbutton {
    background-color: rgba(240, 248, 255, 0);
    border: none;
}
.iamg3{
    display: none;
}
.moonbutton {
    background-color: rgba(240, 248, 255, 0);
    border: none;
    display: none;
}
.LanguageSquare{
    cursor: pointer;
    color: var(--Accent);
    
}
.icon{
    margin: 40px;
    position: absolute;
    left: 0px;
    right: none;
}

.loginImg img {
    position: absolute;
    height: 100vh;
}

.loginImg {
    direction: rtl;
    width: 60%;
    flex: 1;
    background-image: url('../../assets/gif/login-motion.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.loginImg h2 {
    position: fixed;
    font-weight: 300;
    margin: 0;
    color: var(--Subtext);
    /* padding-top: 90vh; */
    /* padding-right: 5vh; */
    bottom: 30px;
    right: 30px;
}

.loginImg span {
    font-weight: 700;
}
.loginImgGrad{
    height: 100%;
    width: 100%;
    background: var(--Gradient);
}
.TextClassy{
    color: var(--Text);
}
#sms-icon{
    color: var(--Text);
}