.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --Error: #ff537b;
  --Warning: #ff9901;
  --Success: #189470;
  --Accent: #7e42ff;
  --TextGradient: linear-gradient(270deg, #7e42ff 0%, #7e42ff60 100%);

  --dark-code-img: url("../src/assets/img/codeImg-dark.jpg");
  --dark-completion-img: url("../src/assets/img/CompletionImg-dark.jpg");
  --dark-menu-img: url("../src/assets/img/menu-dark.png");

  --light-code-img: url("../src/assets/img/codeImg-light.jpg");
  --light-completion-img: url("../src/assets/img/CompletionImg-light.jpg");
  --light-menu-img: url("../src/assets/img/menu-light.png");

  --code-img: var(--light-code-img);
  --completion-img: var(--light-completion-img);
  --menu-img: var(--light-menu-img);

  --dark-Stroke: #505050;
  --dark-Interactive: #292929;
  --dark-Secondary: #0f0f0f;
  --dark-Dialog: #313131;
  --dark-Primary: #1c1c1c;
  --dark-Subtext: #a9a9a9;
  --dark-Text: #ffffff;
  --dark-ButtonText: #ffffff;
  --dark-Gradient: linear-gradient(
    90deg,
    #1c1c1c00 0%,
    rgba(28, 28, 28, 0) 100%
  );
  --dark-Gradient-reverse: linear-gradient(
    270deg,
    #1c1c1c00 0%,
    rgba(28, 28, 28, 0) 100%
  );

  --light-Text: #222222;
  --light-Subtext: #5e5e5e;
  --light-Primary: #ffffff;
  --light-Dialog: #fbfbfb;
  --light-Secondary: #f5f5f5;
  --light-Interactive: #e2e2e2;
  --light-Stroke: #505050;
  --light-ButtonText: #ffffff;
  --light-Gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --light-Gradient-reverse: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  --Text: var(--light-Text);
  --Subtext: var(--light-Subtext);
  --Primary: var(--light-Primary);
  --Dialog: var(--light-Dialog);
  --Secondary: var(--light-Secondary);
  --Interactive: var(--light-Interactive);
  --Stroke: var(--light-Stroke);
  --ButtonText: var(--light-ButtonText);
  --Gradient: var(--light-Gradient);
  --Gradient-reverse: var(--light-Gradient-reverse);

  --fa-font: "Dana";
  --en-font: "Gilroy";
  --font: "Dana";

  --direction: "rtl";
  --direction-ref: "ltr";
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Bold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-DemiBold.woff");
  font-weight: 800;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assets/font/fa/DanaFaNum-Black.woff");
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Bold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-ExtraBold.woff");
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/font/en/Gilroy-Black.woff");
  font-weight: 900;
}
body {
  overflow: hidden;
  font-family: var(--font);
}
.Toastify__toast {
  font-family: var(--font) !important;
}

button {
  cursor: pointer;
  font-family: var(--font);
}
