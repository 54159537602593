.CourseInfoWraper {
  display: flex;
  direction: var(--direction);
}
.main {
  width: calc(100vw - 250px);
  direction: rtl;
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
}

.Courseinfotitle {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Courseinfomain {
  width: 80%;
  margin: auto;
}
.Courseinfomain h4 {
  font-weight: 400;
}
.Courseinfoprof {
  display: flex;
  align-items: center;
}
.Courseinfoprof h5 {
  direction: ltr;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: var(--Subtext);
}
.Courseinfoprof img {
  width: 30px;
  height: 30px;
  border-radius: 8px;
}
.Courseinfomain button {
  border: none;
  color: var(--ButtonText);
  padding: 10px 80px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--Accent);
  opacity: 0.5;
}
.Courseinfomain h2 {
  margin: 15px 45px;
  font-size: 17px;
}
.Courseinfobox1 {
  display: flex;
  background-color: #5e5e5e1d;
  margin: 15px 0;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
}
.Courseinfobox {
  width: 80%;
  margin: auto;
}

.Courseinfoh4 {
  display: flex;
  margin-top: 5%;
  justify-content: center;
  font-size: 19px;
}
.CourseInfoWraper h4,
.CourseInfoWraper h2,
.CourseInfoWraper div {
  color: var(--Text);
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}
.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}

MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.CourseInfoWraper {
  display: flex;
}

.main {
  width: calc(100vw - 250px);
  direction: var(--direction);
  height: 100vh;
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.iconbox,
.Flag {
  display: flex;
  align-items: center;
}

.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}

.img {
  border-radius: 25px;
  width: 100%;
}

.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}

.iconbox span {
  font-weight: 500;
}

.iconbox button {
  background-color: var(--Success);
  border: none;
  width: 50px;
  border-radius: 20px;
  color: var(--ButtonText);
}

.textbox {
  margin: 5px 10px;
}
.Eye {
  margin-right: 5px;
}
.boxbar {
  width: 95%;
  margin: 0px auto;
  /* overflow-y: auto; */
  height: 100%;
  display: flex;

  margin-bottom: 5px;
  flex-wrap: wrap;
}
.texth {
  margin: 2% 5%;
}
.box {
  box-shadow: 1px 2px 30px 2px rgba(100, 100, 100, 0.134);
  margin: 10px;
  border-radius: 25px;
  width: 23%;
  height: 40vh;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

@media screen and (max-width: 1205px) {
  .text h6 {
    font-size: 10px;
  }
  .iconbox button {
    font-size: 10px;
    width: 30px;
  }
  .boxbar {
    width: 100%;
  }
}
