.dashboard h1 {
  color: var(--Accent);
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
}

.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}

MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.signalWraper {
  display: flex;
  direction: var(--direction);
}

.main {
  width: calc(100vw - 250px);
  height: 100vh;
  background: var(--Primary);
  overflow: auto !important;
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
}

.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.iconbox,
.Flag {
  display: flex;
  align-items: center;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}

.img {
  border-radius: 25px;
  width: 100%;
}

.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}

.iconbox span {
  font-weight: 500;
}
.box {
  border-radius: 20px;
  width: 40%;
}
.iconbox button {
  border: none;
  width: 50px;
  border-radius: 20px;
  color: var(--ButtonText);
}

.textbox {
  margin: 5px 10px;
}
.Eye {
  margin-right: 5px;
}
.button {
  width: 100%;
  border-radius: 25px;
  display: flex;
  height: 40px;
  border: none;
  background-color: #ffd738 !important;
  color: var(--ButtonText);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img img {
  width: 65%;
}
.SignalinfoText {
  display: flex;
  margin: 3% 18%;
  gap: 20px;
}
.textBtc {
  width: 47%;
  margin-left: 10%;
  color: var(--Text);
  line-height: 2;
}

.textBtc span {
  font-weight: 700;
}

.button img {
  width: 30%;
}
.cryptoIcon {
  display: block;
  height: 30px;
  border-radius: 90px;
}
@media screen and (max-width: 1250px) {
  .text h6 {
    font-size: 10px;
  }
  .box {
    width: 55%;
  }
  .img img {
    width: 90%;
  }
  .SignalinfoText {
    margin: 8% 8%;
  }
  .textBtc {
    width: 55%;
  }
}
