.CodeImg img {
    position: absolute;
    width: 47%;
    height: 60vw;
    margin-top: -5%;
    flex :1;

}

.CodeImg {
    direction: rtl;
    width: 50%;
    background-image: var(--code-img);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 1;
}

.CodeImg h2 {
    position: fixed;
    font-weight: 300;
    margin: 0;
    color: var(--Subtext);
    /* padding-top: 90vh; */
    /* padding-right: 5vh; */
    bottom: 30px;
    right: 30px;
}

.CodeImg span {
    font-weight: 700;
}
.CodeImggGrad{
    height: 100%;
    width: 100%;
    background: var(--Gradient);
}




.codeWraper {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    background-color: var(--Primary);

}

.codeText {
    max-height: 100%;
    width: 50%;
    direction: rtl;
    flex :1;
    /* background-color: var(--Primary); */
    color: var(--Text);
    display:flex;
}

.Text {
    line-height: 1.7;
    width: 80%;
    max-width: 450px;
    margin: auto;
}

.gradient span {
    font-weight: 900;
    padding-top: 20px;
    background: var(--TextGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a {
    font-size: 13px;
    color: var(--Text);
}

h1 {
    margin: 0;
    font-weight: 500;
}

h6 {
    margin: 30px 0 20px 0;
}

.box {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.box1 {
    width: 15%;
    height: 65px;
    background-color: rgba(2, 26, 18, 0);
    border: 1px solid var( --dark-Subtext);
    border-radius: 17px;
}
.inputCode{
    background: transparent;
    border: 0;
    color: var(--Text);
    font-size: 60px;
    /* text-align: center; */
    width: 100%;
    /* max-width: 450px; */
    height: 65px;
    text-align: center;
    padding: 0;
    padding-inline: 0px;
    padding-block: 0px;
    left: 85px;
    /* initial-letter: normal; */
    /* text-indent: -1em; */
    outline: none;
    font-family: var(--font);
}
.button button {
    width: 100%;
    border-radius: 17px;
    padding: 17px 0;
    border: none;
    color: var(--ButtonText);
    font-size: 17px;
    margin-top: 35px;
    background-color: var(--Accent);
}
.moon{
    color: var(--Accent);
    margin: 0 10px;
    cursor: pointer;
}
.iamg3{
    display: none;
}
.Sun1{
    color: var(--Accent);
    margin: 0 10px;
    cursor: pointer;
    
}
.sunbutton {
    background-color: rgba(240, 248, 255, 0);
    border: none;
}
.moonbutton {
    background-color: rgba(240, 248, 255, 0);
    border: none;
    display: none;

}
.LanguageSquare{
    cursor: pointer;
    color: var(--Accent);
    
}
.icon{
    margin: 40px;
    position: absolute;
    left: 0px;
    right: none;
}