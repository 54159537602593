.signalWraper {
  display: flex;
  direction: var(--direction);
}
.main {
  width: calc(100vw - 250px);
  direction: rtl;
  height: 100vh;
  background: var(--Primary);
}
.dashbord {
  width: 17%;
  height: 100vh;
}
.navbarTap {
  width: 100%;
  height: 6%;
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.teacher {
  display: flex;
  align-items: center;
  margin: 15px 20px 5px 0;
  direction: var(--direction-ref);
}
.teacher h2 {
  color: var(--Text);
  font-size: 20px;
  margin: 6px;
}
.Youtube h5 {
  padding: 0;
  margin: 0;
  color: var(--Text);
}
.Youtube {
  /* background-image: url(../../assets//img//education.png); */
  color: var(--Dialog);
  height: 65%;
  border-radius: 20px 20px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: 100%;
  transition: background-size 0.3s;
}
.Youtube:hover{
    background-size: 130%;
}

.YoutubeText {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--Dialog);
  gap: 7px;
  padding: 5px;
  scale: 1;
  transition: scale 0.3s;
}
.YoutubeText:hover{
    scale: 1.2;
}
.box {
  display: flex;
  cursor: pointer;
}
.minbox {
  background-color: var(--Dialog);
  /* width: 30%; */
  border-radius: 20px;
  margin: 1%;
  height: 25vh;
  scale: 1;
  transition: scale 0.3s;
}
.minbox:hover{
    scale: 1.1;
}
.minbox h2 {
  color: var(--Text);
  font-size: 15px;
  margin: 10px;
  direction: var(--direction-ref);
}
.minbox h3 {
  color: var(--Subtext);
  font-size: 12px;
  margin: 10px;
  direction: var(--direction-ref);
}
.textnemodar h4 {
  color: var(--Text);
  margin-right: 6px;
  margin-left: 6px;
  font-size: 18px;
}
.education {
  width: 95%;
  margin-right: 2%;
}
.textnemodar {
  display: flex;
  align-items: center;
  direction: var(--direction-ref);
}
.navbox {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  justify-content: space-evenly;
}
.nemodar {
  width: 30%;
}
.nemodarBox {
  height: 220px;
  border-radius: 20px;
  padding: 12px 12px;
  background-color: var(--Dialog);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.nemodarBox {
  color: var(--Subtext);
}
.profil {
  cursor: pointer;
  display: flex;
  align-items: center;
  direction: var(--direction-ref);
  justify-content: space-between;
  scale: 1;
  transition: scale 0.3s;

}
.profil:hover{
    scale: 1.05;
}
.imgProf {
  display: flex;
  align-items: center;
}
.profil h5 {
  margin: 14px;
  color: var(--Text);
}
.profil span {
  color: var(--Subtext);
  font-weight: 400;
}
.textProf{
    overflow: hidden;
}
.textProf h5 {
  color: var(--Subtext);
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textnemodar h4 {
  color: var(--Text);
}
.tohi {
  height: 0.1px;
  width: 90%;
  margin: 5px auto;
  background-color: var(--Interactive);
}
.app {
  flex: 1;
  overflow: hidden;
}
.app h5 {
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app h6 {
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.khali {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: rgba(126, 66, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.khali div {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: var(--Accent);
}

.dashboard h1 {
  color: var(--Accent);
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
}
.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}
MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}
.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}
.dashboard img {
  margin-top: -10%;
}
.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}
.icon1 {
  display: none;
}
.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}
.bottomImg h6 {
  color: var(--Text);
}
.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}
.bottomProf {
  margin: 0 5%;
}
.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}
.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}
.logoutimg img {
  width: 70%;
  margin: 0;
}
.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}
.logouticon {
  cursor: pointer;
}

.CryptoScreener {
  width: 100%;
  height: 80vh;
}
.CryptoScreener iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.cryptoIcon {
  display: block;
  height: 30px;
  border-radius: 90px;
}
@media screen and (max-width: 1070px) {
  .cryptoIcon,
  .khali,
  .khali div {
    display: none;
  }
}
