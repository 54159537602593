.WithdrawWraper {
  display: flex;
  direction: var(--direction);
  font-family: var(--font) !important;
}
.main {
  width: calc(100vw - 250px);
  direction: var(--direction-ref);
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}

.WithdrawWraper h4,
.WithdrawWraper h2,
.WithdrawWraper div {
  color: var(--Text);
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}
.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.withdrawHistoryTable {
  width: calc(100vw - 328px);
  height: calc(100vh - 72px);
  /* background-color: red; */
  padding: 20px 20px 0px 20px;
}
td {
  background: var(--Secondary);
  color: var(--Text);
}
th {
  color: var(--ButtonText);
}
.tableStyle {
  width: 100%;
}
