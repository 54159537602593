.dashboard h1 {
  color: var(--Accent);
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.menu {
  direction: var(--direction);
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
}
.filterRoot {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
}
.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}

MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.signalWraper {
  display: flex;
  direction: var(--direction);
}

.main {
  width: calc(100vw - 250px);
  direction: var(--direction-ref);
  height: 100vh;
  background: var(--Primary);
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.box {
  box-shadow: 1px 2px 30px 2px rgba(100, 100, 100, 0.134);
  border-radius: 25px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconbox,
.Flag {
  display: flex;
  align-items: center;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.button {
  width: 100%;
  border-radius: 0 0 25px 25px;
  display: flex;
  height: 40px;
  border: none;
  background-color: var(--Accent);
  color: var(--ButtonText);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}

.img {
  border-radius: 25px 25px 0px 0px;

  width: 100%;
}

.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}

.iconbox span {
  font-weight: 500;
}

.buybutton {
  background-color: var(--Success);
  border: none;
  width: 50px;
  border-radius: 20px;
  color: var(--ButtonText);
}
.sellbutton {
  background-color: var(--Error);
  border: none;
  width: 50px;
  border-radius: 20px;
  color: var(--ButtonText);
}

.textbox {
  margin: 5px 10px;
  color: var(--Subtext);
}

.boxbar {
  margin: 10px auto;
  overflow: scroll;
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  height: 82%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
.cryptoIcon {
  display: block;
  height: 30px;
  border-radius: 90px;
}
.divOptions {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 10px;
}
