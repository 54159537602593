.WithdrawWraper {
  display: flex;
  direction: var(--direction);
  font-family: var(--font) !important;
}
.main {
  width: calc(100vw - 250px);
  direction: rtl;
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}

.WithdrawWraper h4,
.WithdrawWraper h2,
.WithdrawWraper div {
  color: var(--Text);
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}
.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.WithdrawForm {
  width: calc(100vw - 300px);
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.WithdrawWalletAddress {
  width: 500px;
}
.WithdrawButton {
  width: 500px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background: var(--Accent);
  color: var(--ButtonText);
  font-weight: bold;
}
