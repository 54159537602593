@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Bold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-ExtraBold.woff");
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url("../../../src/assets/font/en/Gilroy-Black.woff");
  font-weight: 900;
}

.submitSignalStyle {
  display: flex;
  direction: var(--direction);
  font-family: "Gilroy" !important;
}
.main {
  width: 100vw;
  direction: var(--direction-ref);
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
}

.submitSignalStyle h4,
.submitSignalStyle h2,
.submitSignalStyle div {
  color: var(--Text);
}
.checkes {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.formSystem {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 75%;
  margin: 40px auto;
  direction: ltr;
  font-family: "Gilroy" !important;
}
.formSystem input[type="text"]::placeholder,
.formSystem input[type="password"]::placeholder {
  font-family: "Gilroy";
}
.inputform {
  padding: 19px;
  border-radius: 10px;
  border: 2px solid #000;
  font-size: 20px;
}
.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: "Gilroy";
  height: 34%;
  position: fixed;
  overflow: hidden;
}
.showlogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.showlogo img {
  width: calc(1.4375em + 34.13px);
  border-radius: 10px;
}
.navbarTap {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid var(--Interactive);
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.navbarTap h2 {
  margin-right: 20px;
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.withdrawHistoryTable {
  width: 100vw;
  height: calc(100vh - 70px);
  /* background-color: red; */
}
td {
  background: var(--Secondary);
  color: var(--Text);
}
th {
  color: var(--ButtonText);
}
.tableStyle {
  width: 100%;
}
