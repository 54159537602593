.signalWraper {
  display: flex;
  direction: var(--direction);
}
.main {
  width: calc(100vw - 250px);
  direction: rtl;
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
}
.blurer {
  filter: blur(25px);
  pointer-events: none;
}
.SwiperSlideimg {
  background-image: url(../../assets//img//Frame.png);
  color: var(--Dialog);
  background-size: cover;
  height: 250px;
  border-radius: 25px 25px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxbarTap {
  width: 90%;
  margin: 0 auto;
  padding: 2% 0;
}

.freebox {
  background-color: var(--Dialog);
  box-shadow: 1px 1px 20px 1px rgba(100, 100, 100, 0.134);
  border-radius: 25px;
  margin: 1%;
  /* height: 47vh; */
}
.main h2 {
  color: var(--Text);
}
.freebox h2 {
  color: var(--Text);
  font-size: 15px;
  margin: 5px 10px 0px 0;
}

.freebox h3 {
  color: var(--Subtext);
  font-size: 12px;
  margin: 5px 10px 10px 0;
}

.monybox {
  background-color: var(--Dialog);
  box-shadow: 1px 1px 20px 1px #7e42ff92;
  border-radius: 30px;
  margin: 1%;
  /* height: 48vh; */
  border: 4px solid var(--Accent);
}

.monybox h2 {
  color: var(--Text);
  font-size: 15px;
  margin: 5px 10px 0px 0;
}

.monybox h3 {
  color: var(--Subtext);
  font-size: 12px;
  margin: 5px 10px 10px 0;
}

.SwiperSlide {
  padding: 15%;
}

.SwiperSlidetext {
  padding: 3% 8%;
}

.SwiperSlideporf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row-reverse; */
}

.SwiperSlideporf h2 {
  margin: 0;
}

.SwiperSlideporf h3 {
  margin: 0;
}

.SwiperSlideporfl img {
  width: 35px;
  /* margin-right: 81%; */
  border-radius: 7px;
}

.SwiperSlideprice {
  display: flex;
  align-items: center;
  margin: 3% 0;
  justify-content: space-between;
}

.SwiperSlideprice h4 {
  margin: 0;
  font-size: 12px;
  color: var(--Text);
}

.SwiperSlideprice h2 {
  margin: 0;
  font-size: 17px;
}

.SwiperSlidepricel {
  display: flex;
}

.SwiperSlidepricel img {
  margin: 0 10px;
  height: 20px;
}

.SwiperSlidevisit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin: 5% 0;
  padding-bottom: 10px;
}

.SwiperSlidevisitr {
  display: flex;
  align-items: center;
  color: var(--Subtext);
}

.SwiperSlidevisit h5 {
  margin: 0 5px;
}

.SwiperSlidevisitl {
  display: flex;
  align-items: center;
  color: var(--Subtext);
}

.SwiperSlidebutten {
  background-color: var(--Accent);
  margin-top: -14px;
  border-radius: 0 0 25px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 45px;
}

.SwiperSlidebutten h2 {
  color: var(--ButtonText);
  font-weight: 500;
  margin: 0 5px;
}

.boxbar {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.box {
  box-shadow: 1px 2px 30px 2px rgba(100, 100, 100, 0.134);
  margin: 10px;
  border-radius: 25px;
  width: 21%;
  border: none;
  /* height: 3vh; */
}

/* @media screen and (max-width : 1144px) {
  .SwiperSlideimg {
      height: 20vh;
  }

  .monybox {
      height: 43vh;
  }

  .freebox {
      height: 45vh;
  }

  .SwiperSlidebutten {
      height: 19%;
  }

} */

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}

.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}

MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.signalWraper {
  display: flex;
}

.main {
  width: calc(100vw - 250px);
  direction: var(--direction-ref);
  height: 100vh;
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.iconbox,
.Flag {
  display: flex;
  align-items: center;
}

.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}

.img {
  border-radius: 25px;
  width: 100%;
}

.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}

.iconbox span {
  font-weight: 500;
}

.iconbox button {
  background-color: var(--Success);
  border: none;
  width: 50px;
  border-radius: 20px;

  color: var(--ButtonText);
}

.textbox {
  margin: 5px 10px;
}

.Eye {
  margin-right: 5px;
}

.texth {
  margin: 2% 5%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

@media screen and (max-width: 1205px) {
  .text h6 {
    font-size: 10px;
  }

  .iconbox button {
    font-size: 10px;
    width: 30px;
  }

  .boxbar {
    width: 100%;
  }
}
