.signalWraper {
  display: flex;
  direction: var(--direction);
}
.main {
  width: calc(100vw - 250px);
  direction: rtl;
  overflow: auto;
  height: 100vh;
  background-color: var(--Primary);
}
/* .navbarTap {
    width: 100%;
    height: 6%;
    border-bottom: 1px solid var(--Interactive);
  } */

.Accont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  direction: var(--direction-ref);
}

.Accontimg img {
  border-radius: 50%;
  width: 120px;
}

.Accontbox {
  margin: 20px 0;
  border: 2px solid var(--Interactive);
  width: 40%;
  border-radius: 7px;
  padding: 10px 30px;
  padding-bottom: 20px;
}

.Accontbox h3 {
  font-size: 14px;
  color: var(--Subtext);
}

.Accontname {
  display: flex;
  color: var(--Subtext);
  justify-content: space-between;
}

.Accontname h4 {
  margin: 0;
}

.Accontedit {
  background-color: var(--Interactive);
  color: var(--Subtext);
  font-size: 10px;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 20px;
  opacity: 0.4;
}

.Accontemail {
  display: flex;
  color: var(--Subtext);
  justify-content: space-between;
}

.Accontemail h4 {
  margin: 0;
}

.Accontplan {
  display: flex;
  width: 40%;
  color: var(--ButtonText);
  padding: 2px 30px;
  border-radius: 7px;
  background-color: var(--Accent);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  justify-content: space-between;
}

.Accontplan h3 {
  font-weight: 500;
  font-size: 18px;
}

.Accontlink {
  display: flex;
  width: 40%;
  color: var(--Text);
  padding: 1px 30px;
  border-radius: 7px;
  border: 1px dashed var(--Accent);
  margin: 20px 0;
  border-width: 2px;
  justify-content: space-between;
}

.Accontlinkl {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.Accontlinkl h3 {
  margin: 0 5px;
}

.Accontinventory {
  display: flex;
  width: 40%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  color: var(--ButtonText);
  padding: 2px 30px;
  border-radius: 7px;
  background-color: var(--Accent);
  justify-content: space-between;
}

.Accontinventory h3 {
  font-weight: 500;
  font-size: 18px;
}

.Accontbut {
  display: flex;
  width: calc(40% + 60px);
  color: var(--ButtonText);
  border-radius: 7px;
  margin: 20px 0;
  justify-content: space-between;
  gap: 20px;
}
.Accontnamer {
  font-family: var(--fa-font);
}
.Accontbut h3 {
  margin: 10px 10px;
  font-weight: 500;
  font-size: 15px;
}

.Acconthistory {
  display: flex;
  align-items: center;
  color: var(--ButtonText);
  padding: 5px 0px;
  width: 100%;
  cursor: pointer;
  /* margin: 0 5px; */
  justify-content: center;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 7px;
  background-color: var(--Accent);
}

.Accontharvest {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--ButtonText);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.61) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 5px 0px;
  width: 100%;
  justify-content: center;
  /* margin: 0 5px; */
  border-radius: 7px;
  background-color: var(--Accent);
}

.income {
  width: calc(40% + 60px);
  color: var(--Text);
  display: flex;
  border-radius: 7px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--Interactive);
}

.income h3 {
  font-size: 25px;
  margin: 19px;
}

table {
  margin: 20px 0;
  width: calc(40% + 60px);
  border-collapse: collapse;
  text-align: center;
}

tr {
  height: 43px;
  font-size: 12px;
  background-color: var(--Interactive);
}
th {
  color: var(--Text);
}
tr:nth-child(1) {
  background-color: var(--Accent);
  color: var(--ButtonText);
}

.arrow {
  margin: 0px 28px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--Text);
  display: none;
}

.arrow h2 {
  cursor: pointer;
  padding-left: 10px;
}

@media screen and (max-width: 1144px) {
  .Accontharvest {
    padding: 5px;
  }
  .Acconthistory {
    padding: 5px;
  }
}

.menu {
  direction: rtl;
  color: var(--Subtext);
  font-family: 600;
  height: 34%;
  position: fixed;
  overflow: hidden;
}

.menu h6 {
  margin-right: 9%;
  font-size: 12px;
}

MenuItem {
  align-items: center;
  padding-right: 9%;
  display: flex;
}

.dashboardIcon {
  display: flex;
  margin-top: -7%;
  justify-content: center;
}

.dashboard img {
  margin-top: -10%;
}

.icon {
  padding: 5px;
  display: inline;
  cursor: pointer;
}

.icon1 {
  display: none;
}

.bottomProf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomImg {
  display: flex;
  align-items: center;
  direction: rtl;
  margin-right: 9px;
}

.bottomImg h6 {
  color: var(--Text);
}

.bottomIcon {
  background-color: var(--Interactive);
  text-align: center;
  cursor: pointer;
  width: 25px;
  border-radius: 20px;
}

.bottomProf {
  margin: 0 5%;
}

.logout {
  display: flex;
  margin: 0 4%;
  align-items: center;
  justify-content: space-between;
}

.logoutimg {
  display: flex;
  align-items: center;
  line-height: 2;
}

.logoutimg img {
  width: 70%;
  margin: 0;
}

.logoutimg h6 {
  margin: 0 5px;
  margin-top: -9px;
}

.logouticon {
  cursor: pointer;
}

.signalWraper {
  display: flex;
}

.main {
  width: calc(100vw - 250px);
  direction: var(--direction-ref);
  height: 100vh;
  border-right: 1px solid var(--Interactive);
  border-left: 1px solid var(--Interactive);
}

.dashbord {
  width: 17%;
  height: 100vh;
}

.navbarTap {
  width: 100%;
  height: 6%;
  border-bottom: 1px solid var(--Interactive);
}
.navbarTap iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.iconbox,
.Flag {
  display: flex;
  align-items: center;
}

.text h6 {
  margin: 10px;
  color: var(--Subtext);
  font-size: 13px;
}

.img {
  border-radius: 25px;
  width: 100%;
}

.iconbox h6 {
  color: var(--Text);
  font-weight: 700;
}

.iconbox span {
  font-weight: 500;
}

.iconbox button {
  background-color: var(--Success);
  border: none;
  width: 50px;
  border-radius: 20px;

  color: var(--ButtonText);
}

.textbox {
  margin: 5px 10px;
}

.Eye {
  margin-right: 5px;
}

.texth {
  margin: 2% 5%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
td {
  height: 43px;
}
@media screen and (max-width: 1205px) {
  .text h6 {
    font-size: 10px;
  }

  .iconbox button {
    font-size: 10px;
    width: 30px;
  }

  .boxbar {
    width: 100%;
  }
}
